<template>
  <section class="vh-100 overflow-auto" style="height: 100%">
    <LoadingSpinner v-if="isLoading" style="height: 100vh;"></LoadingSpinner>

    <!-- 상단 배너 -->
    <div class="header d-flex justify-content-between align-items-center py-3">
      <button class="btn btn-link back-btn" @click="goBackward">
        <font-awesome-icon icon="fa fa-arrow-left" />
      </button>
      <!-- Egg -->
      <div class="logo-box" @click="onLogoClick">
        <img :src="ezlogo" class="header-logo" alt="농업e지 로고" />
      </div>
    </div>
    <!-- 인포 라벨 -->
    <div class="step-label py-3 text-left">
      <h1>Step 3. 교육영상</h1>
    </div>
    <!-- 유튜브 플레이어 -->
    <div class="youtube-container">
      <YouTube :src="youtubeUrl" @ready="onReady" @state-change="onStateChange"
        ref="youtube" :vars="vars" />
    </div>
    <!-- 다음으로 -->
    <div class="text-left mx-auto d-grid mb-4">
      <div class="text-left mt-4">
        <button class="btn btn-primary btn-lg full-width-btn" @click="goForward" :disabled="isPlayEnd">
          <strong>농업e지 로그인하기</strong>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import YouTube from 'vue3-youtube';

export default {
  name: "LinkView5",
  components: {
    LoadingSpinner,
    YouTube,
  },
  data() {
    return {
      ezlogo: "/assets/images/ez_logo.png",
      isLoading: false,
      isPlayEnd: true,
      youtubeUrl: "https://youtu.be/RlwWou_o7lo?si=QoGm86fLeq7ifKmU", 
      /*
        30초 : https://youtu.be/yieHA1519o4?si=6QLlajQc2VcLrwT2
        원본 : https://youtu.be/RlwWou_o7lo?si=QoGm86fLeq7ifKmU
      */
      vars: {
        controls: 0,
        autoplay: 0,
        disablekb: 1, // 스킵 방지
      },
      logoClickCount: 0,
    };
  },
  mounted() {
    // 창 크기 변경 이벤트 추가
    window.addEventListener("resize", this.updateIframeSize);
    // 오른쪽 화살표 키 이벤트 차단
    window.addEventListener("keydown", this.disableRightArrowKey);
  },
  beforeUnmount() {
    // 창 크기 변경 이벤트 제거
    window.removeEventListener("resize", this.updateIframeSize);
    // 오른쪽 화살표 키 이벤트 제거
    window.removeEventListener("keydown", this.disableRightArrowKey);
  },
  methods: {
    onReady() {
      this.player = this.$refs.youtube.player;
      this.updateIframeSize();
      // 매 초마다 실행될 Interval 설정
      this.interval = setInterval(this.onTimeUpdate, 1000); // 1초마다 실행
    },
    onTimeUpdate() {
      if (!this.player || typeof this.player.getCurrentTime !== "function") {
        console.warn("YouTube player instance가 없거나 getCurrentTime 메서드가 없습니다.");
        return;
      }
      const currentTime = this.player.getCurrentTime();
      const totalDuration = this.player.getDuration() * 0.1 > 10 ? 10 : this.player.getDuration() * 0.1;
      if (currentTime < totalDuration) {
        this.isPlayEnd = true;
      } else {
        this.isPlayEnd = false;
      }
      console.log("현재 재생 시간 (매 초마다):", currentTime, totalDuration, this.isPlayEnd);
    },
    updateIframeSize() {
      const maxWidth = 640;
      const maxHeight = 360;
      const aspectRatio = 16 / 9;

      const screenWidth = window.innerWidth;
      let width, height;

      if (screenWidth > maxWidth) {
        // 화면 크기가 640보다 크면 기본 크기 유지
        width = maxWidth;
        height = maxHeight;
      } else {
        // 화면 크기가 640보다 작으면 비율에 따라 크기 조정
        width = screenWidth;
        height = Math.round(width / aspectRatio); // 16:9 비율 유지
      }

      console.log("플레이어 크기 설정:", { width, height });

      if (this.player && typeof this.player.setSize === "function") {
        // YouTube 플레이어 크기 설정
        this.player.setSize(width, height);
      } else {
        console.warn("YouTube player instance가 없거나 setSize 메서드가 없습니다.");
      }

      // iframe 스타일도 동기화 (예: setSize 동작이 예상대로 되지 않을 경우 대응)
      const iframe = this.$refs.youtube.$el.querySelector("iframe");
      if (iframe) {
        iframe.style.width = `${width}px`;
        iframe.style.height = `${height}px`;
        console.log("iframe 스타일 동기화 완료");
      }
    },
    onStateChange(event) {
      const player = this.$refs.youtube.player;

      // 동영상 총시간 가져오기
      const totalDuration = player.getDuration() * 0.1 > 10 ? 10 : player.getDuration() * 0.1;
      console.log("총 동영상 시간:", totalDuration, player.getDuration());

      // 현재 재생 시간 가져오기
      const currentTime = player.getCurrentTime();
      console.log("현재 재생 시간:", currentTime);

      if (currentTime < totalDuration) {
        this.isPlayEnd = true;
      } else {
        this.isPlayEnd = false;
      }

      if (event.data === 0) {
        this.isLoading = false;
      }
    },
    disableRightArrowKey(event) {
      if (event.key === "ArrowRight") {
        event.preventDefault();
        console.log("오른쪽 화살표 키 동작 차단");

        if (this.player) {
          const currentTime = this.player.getCurrentTime();
          this.player.seekTo(currentTime, true); // 현재 시간을 유지해 화살표 동작 방지
        }
      }
    },
    goBackward() {
      this.isLoading = true;
      this.$router.push("/link4");
    },
    goForward() {
      this.isLoading = true;
      this.$router.push("/link6");
    },
    onLogoClick() {
      this.logoClickCount++;
      if (this.logoClickCount >= 5) {
        this.isPlayEnd = false;
      }
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.back-btn {
  font-size: 1.5rem;
  color: #505050;
  border: none;
  cursor: pointer;
}

.header-logo {
  width: 100px;
  height: auto;
}

.step-label {
  background-color: #f95a25;
  color: white;
  padding: 10px;
  width: 100%;
  max-width: 100%;
}

.step-label h1 {
  font-size: 1.2rem;
  margin: 0;
}

.full-width-btn {
    background-color: #2c387e;
    border: none;
    font-size: 1rem;
    padding: 15px 30px;
    text-align: center;
    width: 100%;
    border-radius: 16px;
    margin: 0 auto; 
    position: relative;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.youtube-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  touch-action: none;
}

.youtube-container iframe {
  max-width: 100%;
  max-height: 60vh;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
</style>