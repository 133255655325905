<template>
  <section class="vh-100 overflow-auto" style="height: 100%">
    <LoadingSpinner v-if="isLoading" style="height: 100vh;"></LoadingSpinner>

    <!-- 상단 배너 -->
    <div class="header d-flex justify-content-between align-items-center py-3">
      <button class="btn btn-link back-btn" @click="goBackward">
        <font-awesome-icon icon="fa fa-arrow-left" />
      </button>
      <div class="logo-box">
        <img :src="ezlogo" class="header-logo" alt="농업e지 로고" />
      </div>
    </div>
    <!-- 인포 라벨 -->
    <div class="step-label py-3 text-left">
      <h1>수강 완료하기</h1>
    </div>
    <!-- 사용자 입력란 -->
    <div class="container mt-4">
      <form>
        <div class="mb-3">
          <label for="student_name" class="form-label">성함</label>
          <input type="text" class="form-control" placeholder="홍길동" id="student_name" ref="student_name"
            v-model="selectedStudent.studentName" @input="validateName">
        </div>
        <div class="mb-3">
          <label for="student_phone" class="form-label">전화번호</label>
          <input type="tel" class="form-control" placeholder="010-1234-1234" id="student_phone" ref="student_phone"
            v-model="selectedStudent.studentPhone" @input="validatePhone" :class="{ invalid: !isPhoneValid }">
        </div>
        <div class="mb-3">
          <label for="student_birth" class="form-label">생년월일(yyyy-MM-dd)</label>
          <input type="text" class="form-control" placeholder="1900-12-31" id="student_birth" ref="student_birth"
            v-model="selectedStudent.studentBirth" @input="validateDob" :class="{ invalid: !isBirthValid }">
        </div>
        <div class="mb-3">
          <label for="student_number" class="form-label">경영체 등록번호(0-000-000-000)</label>
          <div class="input-group">
            <input type="text" class="form-control" placeholder="0-000-000-000" id="student_number" ref="student_number"
              v-model="selectedStudent.studentNumber" @input="validateStudentNumber"
              :class="{ invalid: !isStudentNumberValid }">
            <button class="btn btn-secondary" type="button" @click="showPopup">농업e지<br />페이지 열기</button>
          </div>
        </div>
        <div class="mb-3">
          <label for="teacher_name" class="form-label">농업e지쌤 이름(지역)</label>
          <input type="text" class="form-control" id="teacher_name" v-model="teacherName" readonly>
        </div>
        <div class="mb-3">
          <label for="teacher_link" class="form-label">농업e지쌤 고유번호</label>
          <input type="text" class="form-control" id="teacher_link" v-model="teacherLink" readonly>
        </div>
      </form>
    </div>
    <!-- 다음으로 -->
    <div class="text-left mx-auto d-grid mb-4">
      <div class="text-left mt-4">
        <button class="btn btn-primary btn-lg full-width-btn" @click="goForward()">
          <span class="thin-text">수료하기</span><br>
        </button>
      </div>
    </div>
    <!-- 농업e지 팝업 -->
    <div :class="['slide-popup', { visible: isPopupVisible }]">
      <div class="popup-header">
        <button class="close-button" @click="closePopup">
          <font-awesome-icon icon="fa fa-arrow-left" />
          <span class="ms-2">신청서로 돌아가기</span>
        </button>
      </div>
      <iframe src="https://www.nongupez.go.kr/" frameborder="0" class="popup-iframe"></iframe>
    </div>
  </section>
</template>

<script>
//
// 2024.12.19. edited by green, 교육생 정보 저장 단계를 줄임
//
import STUDENT from "@/api/student.js";
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  name: "LinkView6",
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      ezlogo: "/assets/images/ez_logo.png",
      isLoading: false,
      isPopupVisible: true,
      teacherLink: "",
      teacherName: "",
      selectedStudent: {},
      isPhoneValid: true, // 전화번호 유효성 상태
      isBirthValid: true, // 생년월일 유효성 상태
      isStudentNumberValid: true, // 경영체 등록번호 유효성 상태
    };
  },
  mounted() {
    // localStorage에서 값 불러오기
    const storedTeacherLink = localStorage.getItem("teacherLink");
    if (storedTeacherLink) {
      this.teacherLink = storedTeacherLink; // 읽기 전용 입력란에 값 설정
    }
    const storedTeacherName = localStorage.getItem("teacherName");
    if (storedTeacherName) {
      this.teacherName = storedTeacherName; // 읽기 전용 입력란에 값 설정
    }
  },
  methods: {
    goBackward() {
      this.isLoading = true;
      this.$router.push("/link5");
    },
    goForward() {
      // 입력란 값 검증
      const missingFields = [];
      const student_name = this.$refs.student_name?.value || "";
      const student_phone = this.$refs.student_phone?.value || "";
      const student_birth = this.$refs.student_birth?.value || "";
      const student_number = this.$refs.student_number?.value || "";

      if (!student_name) missingFields.push("성함");
      if (!student_phone) missingFields.push("전화번호");
      if (!student_birth) missingFields.push("생년월일");
      if (!student_number) missingFields.push("경영체 등록번호");

      const errorFields = [];
      if (student_phone) {
        const phonePattern = /^\d{3}-\d{3,4}-\d{4}$/;
        this.isPhoneValid = phonePattern.test(student_phone); // 유효하면 true, 그렇지 않으면 false
        if (!this.isPhoneValid) errorFields.push("전화번호");
        console.debug("전화번호:", student_phone);
      }
      if (student_birth) {
        const dobPattern = /^\d{4}-\d{2}-\d{2}$/;
        this.isBirthValid = dobPattern.test(student_birth); // 유효하면 true, 그렇지 않으면 false
        if (!this.isBirthValid) errorFields.push("생년월일");
        console.debug("생년월일:", student_birth);
      }
      if (student_number) {
        const studentNumberPattern = /^\d{1}-\d{3}-\d{3}-\d{3}$/; // 0-000-000-000 형식
        this.isStudentNumberValid = studentNumberPattern.test(student_number); // 유효하면 true, 그렇지 않으면 false
        if (!this.isStudentNumberValid) errorFields.push("경영체 등록번호");
        console.debug("경영체 등록번호:", student_number);
      }

      if (missingFields.length > 0 || errorFields.length > 0) {
        let message = "";
        if (missingFields.length > 0) {
          message += `다음 항목이 누락되었습니다:\n\n${missingFields.join(", ")}\n\n`;
        }
        if (errorFields.length > 0) {
          message += `다음 항목이 올바르지 않습니다:\n\n${errorFields.join(", ")}`;
        }
        alert(message);
      } else {
        // 모든 입력란이 채워진 경우 다음 페이지로 이동
        this.addStudent();
      }
    },
    validateName(event) {
      const value = event.target.value;
      event.target.value = value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z0-9\s]/g, ""); // 한글, 영문, 공백만 허용
      this.selectedStudent.studentName = event.target.value;
    },
    validatePhone(event) {
      let value = event.target.value.replace(/[^0-9]/g, ""); // 숫자 외 제거
      if (value.length > 3 && value.length <= 6) {
        value = `${value.slice(0, 3)}-${value.slice(3)}`; // 3자리 뒤에 하이픈
        console.debug("전화번호 1:", value);
      } else if (value.length > 6 && value.length <= 10) {
        value = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6)}`; // 3-3-4 형식
        console.debug("전화번호 2:", value);
      } else if (value.length > 10) {
        value = `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7)}`; // 3-4-4 형식
        console.debug("전화번호 3:", value);
      }
      value = value.slice(0, 13); // 13자리까지만 허용
      console.debug("전화번호:", value);
      event.target.value = value;
      this.selectedStudent.studentPhone = value;

      // 유효성 검사
      const phonePattern = /^\d{3}-\d{3,4}-\d{4}$/;
      this.isPhoneValid = phonePattern.test(value); // 유효하면 true, 그렇지 않으면 false
    },
    validateDob(event) {
      let value = event.target.value.replace(/[^0-9]/g, ""); // 숫자 외 제거
      if (value.length > 4 && value.length <= 6) {
        value = `${value.slice(0, 4)}-${value.slice(4)}`; // 4자리 뒤에 하이픈
      } else if (value.length > 6) {
        value = `${value.slice(0, 4)}-${value.slice(4, 6)}-${value.slice(6)}`; // YYYY-MM-DD 형식
      }
      value = value.slice(0, 10); // 10자리까지만 허용

      if (value.length === 10) {
        const [year, month, day] = value.split("-").map(Number);

        // 날짜 유효성 체크
        const isValidDate = (y, m, d) => {
          const date = new Date(y, m - 1, d); // 월은 0부터 시작
          return (
            date.getFullYear() === y &&
            date.getMonth() + 1 === m &&
            date.getDate() === d
          );
        };

        if (!isValidDate(year, month, day)) {
          alert("유효한 날짜를 입력해 주세요.");
          value = value.slice(0, 9); // 잘못된 날짜일 경우 입력값 마지막 문자 제거
        }
      }

      event.target.value = value;
      this.selectedStudent.studentBirth = event.target.value;

      // 유효성 검사
      const studentBirthPattern = /^\d{4}-\d{2}-\d{2}$/; // 0000-00-00 형식
      this.isBirthValid = studentBirthPattern.test(value); // 유효하면 true, 그렇지 않으면 false

      if (!this.isBirthValid) {
        console.error("유효하지 않은 생년월일 형식입니다:", value);
      }

    },
    validateStudentNumber(event) {
      let value = event.target.value.replace(/[^0-9]/g, ""); // 숫자 외 제거
      if (value.length > 1 && value.length <= 4) {
        value = `${value.slice(0, 1)}-${value.slice(1)}`; // 첫 번째 숫자 뒤에 하이픈 추가
      } else if (value.length > 4 && value.length <= 7) {
        value = `${value.slice(0, 1)}-${value.slice(1, 4)}-${value.slice(4)}`; // 0-000- 형식
      } else if (value.length > 7) {
        value = `${value.slice(0, 1)}-${value.slice(1, 4)}-${value.slice(4, 7)}-${value.slice(7)}`; // 0-000-000-000 형식
      }
      value = value.slice(0, 13); // 13자리까지만 허용
      console.debug("전화번호:", value);
      event.target.value = value;
      this.selectedStudent.studentNumber = value;

      // 유효성 검사
      const studentNumberPattern = /^\d{1}-\d{3}-\d{3}-\d{3}$/; // 0-000-000-000 형식
      this.isStudentNumberValid = studentNumberPattern.test(value); // 유효하면 true, 그렇지 않으면 false

      if (!this.isStudentNumberValid) {
        console.error("유효하지 않은 경영체 등록번호 형식입니다:", value);
      }
    },
    showPopup() {
      this.isPopupVisible = true; // 팝업 표시
    },
    closePopup() {
      this.isPopupVisible = false; // 팝업 닫기
    },
    openAddModal() {
      this.selectedStudent = {
        teacherId: '',
        studentName: '',
        studentPhone: '',
        studentBirth: '',
        studentNumber: '',
        courseTime: '',
        courseStatus: '',
        createdDt: '',
      };
      this.isAdding = true;
      this.showEditModal = true;
    },
    async addStudent() {
      try {
        // 로컬스토리지에서 teacherId 가져오기
        const teacherLink = localStorage.getItem("teacherLink");
        console.debug("가져옵니다: " + teacherLink);
        if (!teacherLink) {
          alert("교사 정보가 누락되었습니다. 다시 시도해 주세요.");
          return;
        }

        const studentData = {
          teacherLink: teacherLink,
          studentName: this.selectedStudent.studentName,
          studentPhone: this.selectedStudent.studentPhone,
          studentBirth: this.selectedStudent.studentBirth,
          studentNumber: this.selectedStudent.studentNumber,
          courseStatus: "Y",
          agreeStatus: "N",
        };

        localStorage.setItem("studentData", JSON.stringify(studentData));

        // 2024.12.19. edited by green, 교육생 정보 저장 단계를 줄임
        // this.isLoading = true;
        // this.$router.push("/link7");

        console.debug("studentData", studentData);
        await STUDENT.insert(studentData);
        alert("교육생 정보가 성공적으로 저장되었습니다.");
        this.isLoading = true;
        this.$router.push("/link8");
      }
      catch (error) {
        console.error("교육생 정보 저장 실패:", error);
        alert("교육생 정보 저장 중 문제가 발생했습니다.");
      }
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.back-btn {
  font-size: 1.5rem;
  color: #505050;
  border: none;
  cursor: pointer;
}

.header-logo {
  width: 100px;
  height: auto;
}

.step-label {
  background-color: #f95a25;
  color: white;
  padding: 10px;
  width: 100%;
  max-width: 100%;
}

.step-label h1 {
  font-size: 1.2rem;
  margin: 0;
}

/* 입력란 스타일 */
.form-label {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.form-control {
  border-radius: 8px;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
}

.form-control::placeholder {
  color: #cccccc;
  opacity: 1;
}

.invalid {
  border: 1px solid red;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.full-width-btn {
  background-color: #2c387e;
  border: none;
  font-size: 1rem;
  padding: 15px 30px;
  text-align: center;
  width: 100%;
  border-radius: 16px;
  margin: 0 auto;
  position: relative;
}

/* 농업e지 팝업 */
.slide-popup {
  position: fixed;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: 95%;
  background-color: #fff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  z-index: 1000;
  transition: bottom 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
}

.slide-popup.visible {
  bottom: 0;
}

.popup-header {
  padding: 1em;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-header h5 {
  margin: 0;
  font-size: 1.2rem;
}

.close-button {
  font-size: 1.5rem;
  color: #505050;
  background-color: #fff;
  font-weight: bold;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.popup-iframe {
  flex: 1;
  width: 100%;
  border: none;
}
</style>