<template>
  <div class="container-fluid" style="display: block;">
    <div class="row">

      <!-- 제목과 추가 버튼 -->
      <div class="col-md d-flex justify-content-between align-items-center">
        <div class="fs-2 fw-bold"> 교육현황 </div>
        <button type="button" class="btn btn-success" @click="openAddModal">교육생 추가</button>
      </div>

      <div class="col-md">
        <div class="row justify-content-end">
          <div class="col text-end">
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="검색" aria-label="search-value"
                v-model="searchValue" />
              <button type="button" class="btn btn-primary" @click="getDatas">검색</button>
              <button type="button" class="btn btn-outline-secondary" @click="resetDatas"><font-awesome-icon
                  icon="fa-solid fa-eraser" /></button>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="row">
      <LoadingSpinner v-if="isLoading"></LoadingSpinner>
      <div class="row gx-2 justify-content-end">
        <!-- S: 페이지 네비게이션 -->
        <div class="col-md-10 d-flex justify-content-center align-items-center">
          <button type="button" class="btn btn-secondary me-2" :disabled="currentPage === 1"
            @click="navigatePage(currentPage - 1)">
            <font-awesome-icon icon="fa-solid fa-chevron-left" />
          </button>
          <span>
            페이지 {{ currentPage }} / {{ totalPages }} (총 {{ totalCount }} 건)
          </span>
          <button type="button" class="btn btn-secondary ms-2" :disabled="currentPage === totalPages"
            @click="navigatePage(currentPage + 1)">
            <font-awesome-icon icon="fa-solid fa-chevron-right" />
          </button>
        </div>
        <!-- E: 페이지 네비게이션 -->
        <div class="col-md-2 text-end">
          <button class="btn btn-outline-secondary btn-sm" style="width: auto;" @click="onBtnExport">Excel 내보내기</button>
        </div>
      </div>

      <GridTemplate ref="grid" :columns="columnDefs" :dataSource="dataSource" @grid-ready="onGridReady"
        :framework-components="frameworkComponents" :cellSelection="cellSelection"
        :enableCellTextSelection="enableCellTextSelection" />
    </div>

    <!-- S: 수정/추가 레이어 모달 -->
    <div v-if="showEditModal" class="modal" tabindex="-1" role="dialog" style="display: block;">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ isAdding ? '교육생 추가' : '교육생 수정' }}</h5>
            <button type="button" class="close" @click="closeEditModal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="studentName">이름</label>
              <input type="text" id="studentName" class="form-control" v-model="selectedData.studentName" />
            </div>
            <div class="form-group">
              <label for="studentPhone">연락처</label>
              <input type="text" id="studentPhone" class="form-control" v-model="selectedData.studentPhone" />
            </div>
            <div class="form-group">
              <label for="studentGender">생일</label>
              <input type="date" id="studentBirth" class="form-control" v-model="selectedData.studentBirth" />
            </div>
            <div class="form-group">
              <label for="studentNumber">경영체 등록번호</label>
              <input type="text" id="studentNumber" class="form-control" v-model="selectedData.studentNumber" />
            </div>
            <div class="form-group">
              <label for="courseTime">수료시간</label>
              <input type="number" id="courseTime" class="form-control" v-model="selectedData.courseTime" min="0" />
            </div>
            <div class="form-group">
              <label for="courseStatus">수료현황</label>
              <input type="text" id="courseStatus" class="form-control" v-model="selectedData.courseStatus" />
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-between">
            <!-- 삭제 버튼: 항상 보이지만, isAdding일 때 비활성화 -->
            <button type="button" class="btn btn-danger" :disabled="isAdding" @click="deleteData">삭제</button>
            <div>
              <button type="button" class="btn btn-primary" @click="isAdding ? addData() : saveData()">저장</button>
              <button type="button" class="btn btn-secondary" @click="closeEditModal">닫기</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- E: 수정/추가 레이어 모달 -->

    <!-- S: 이지쌤 상세 모달 -->
    <div v-if="showTeacherModal" class="modal" tabindex="-1" role="dialog" style="display: block;">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">이지쌤 상세 정보</h5>
            <button type="button" class="close" @click="closeTeacherModal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="teacherName">이름:</label>
              <span>{{ selectedTeacher.teacherName }}</span>
            </div>
            <div class="form-group">
              <label for="teacherRegion">지역:</label>
              <span>{{ selectedTeacher.teacherRegion }}</span>
            </div>
            <div class="form-group">
              <label for="teacherPhone">연락처:</label>
              <span>{{ selectedTeacher.teacherPhone }}</span>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeTeacherModal">닫기</button>
          </div>
        </div>
      </div>
    </div>
    <!-- E: 이지쌤 상세 모달 -->

  </div>
</template>

<script>
import STUDENT from "@/api/student.js";
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import ButtonRenderer from '@/components/ButtonRenderer.vue';

export default {
  name: "StudentView",
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      gridApi: null,
      columnDefs: [
        { headerName: "이름", field: "studentName", pinned: 'left' },
        { headerName: "연락처", field: "studentPhone" },
        { headerName: "생일", field: "studentBirth" },
        { headerName: "경영체 등록번호", field: "studentNumber" },
        {
          headerName: "이지쌤", field: "teacherName",
          cellRenderer: (params) => {
            if (params.data && params.data.teacher) {
              const teacher = params.data.teacher;
              return `<a href="#" style="color:blue; text-decoration:underline;" onclick="window.teacherClick(${teacher.id})">${teacher.teacherName}</a>`;
            }
            return null;
          },
        },
        { headerName: "이지쌤 지역", field: "teacherRegion", valueGetter: (params) => params.data.teacher?.teacherRegion || '' },
        { headerName: "이지쌤 링크", field: "teacherLink" },
        { headerName: "수료시간", field: "courseTime" },
        { headerName: "수료현황", field: "courseStatus" },
        { headerName: "동의현황", field: "agreeStatus" },
        { headerName: "생성일시", field: "createdDt" },
        {
          headerName: "제어", field: "actions",
          cellRenderer: ButtonRenderer,
          cellRendererParams: (params) => ({
            label: '수정',
            onClick: () => {
              if (params && params.data) {
                this.openEditModal(params.data);
              }
            },
          }),
        },
      ],
      enableCellTextSelection: true,
      cellSelection: true,
      dataSource: [],
      defaultColDef: {
        sortable: true,
        flex: 1,
        resizable: true,
        filter: 'agTextColumnFilter',
        menuTabs: ['filterMenuTab'],
      },
      autoSizeStrategy: {
        type: 'fitGridWidth',
        defaultMinWidth: 30,
      },
      frameworkComponents: {
        ButtonRenderer: ButtonRenderer, // ButtonRenderer 컴포넌트 등록
      },
      searchValue: '',
      totalCount: 0,
      currentPage: 1,
      rowsPerPage: 100,
      isLoading: false,
      isAdding: false, // 추가 모드 플래그
      showEditModal: false,
      selectedData: {},
      showTeacherModal: false,
      selectedTeacher: {}, // teacher 정보 저장
    }
  },
  created() {
    let date = new Date();
    const offset = date.getTimezoneOffset()
    date = new Date(date.getTime() - (offset * 60 * 1000));
    this.endDt = date.toISOString().split('T')[0]
    window.teacherClick = (teacherId) => {
      const teacher = this.dataSource.find(item => item.teacher?.id === teacherId)?.teacher;
      if (teacher) {
        this.openTeacherDetailsModal(teacher);
      }
    };
    this.getDatas();
  },
  computed: {
    // yyyyMMdd -> yyyy-MM-dd 포맷으로 변환
    formattedStudentBirth() {
      if (!this.selectedData.studentBirth) return '';
      const birth = this.selectedData.studentBirth;
      return `${birth.slice(0, 4)}-${birth.slice(4, 6)}-${birth.slice(6, 8)}`;
    },
    totalPages() {
      // 전체 페이지 수 계산
      return Math.ceil(this.totalCount / this.rowsPerPage);
    },
  },
  methods: {
    onGridReady(params) {
      console.log('Grid Ready Params:', params); // API 확인
      this.gridApi = params.api;
      // params.api.setGridOption('serverSideDatasource', getServerSideDatasource());
      if (!this.gridApi) {
        console.error('Grid API 초기화 실패');
      } else {
        console.log('Grid API가 정상적으로 초기화되었습니다.');
      }
    },
    onBtnExport() {
      console.log('Grid onBtnExport');
      if (!this.gridApi) {
        console.error('Grid API가 아직 초기화되지 않았습니다.');
        alert('데이터를 내보낼 수 없습니다. 그리드가 아직 준비되지 않았습니다.');
        return;
      }
      this.isLoading = true;
      try {
        let { searchValue, currentPage, rowsPerPage } = this;
        const param = {
          search_value: searchValue,
          current_page: currentPage,
          rows_per_page: rowsPerPage,
        };
        STUDENT.getDownload(param);
        console.log('Excel 내보내기 완료');
      } catch (error) {
        console.error('Excel 내보내기 중 오류 발생:', error);
        alert('Excel 내보내기 중 오류가 발생했습니다. 콘솔을 확인하세요.');
      }
      this.isLoading = false;
    },
    async getDatas() {
      try {
        let { searchValue, currentPage, rowsPerPage } = this;
        const param = {
          search_value: searchValue,
          current_page: currentPage,
          rows_per_page: rowsPerPage,
        };
        this.isLoading = true;
        const { data } = await STUDENT.getList(param);
        const students = [];
        console.log('교육생 목록:', data);
        if (data !== 'undefined' && data != null) {
          this.totalCount = data.count;
          const results = data.results;
          console.debug('교육생 수:', this.totalCount);
          results.forEach(result => {
            const student = result.student;
            const teacher = result.teacher;
            if (teacher !== 'undefined' && teacher != null) {
              student.teacher = teacher;
            } else {
              student.teacher = null;
            }
            students.push(student);
          });
          this.dataSource = students;
        }
      } catch (error) {
        alert(error.data);
        console.log("오류: ", error);
      }
      this.isLoading = false;
    },
    navigatePage(page) {
      if (page < 1 || page > this.totalPages) return; // 페이지 범위를 벗어난 경우 무시
      this.currentPage = page; // 현재 페이지 변경
      this.getDatas(); // 데이터 다시 로드
    },
    resetDatas() {
      this.searchValue = '';
    },
    openEditModal(data) {
      this.isAdding = false;
      this.selectedData = { ...data };
      this.showEditModal = true;
    },
    closeEditModal() {
      this.showEditModal = false;
    },
    openTeacherDetailsModal(teacher) {
      this.selectedTeacher = teacher;
      this.showTeacherModal = true;
    },
    closeTeacherModal() {
      this.showTeacherModal = false;
    },
    saveData() {
      // 저장 로직 구현
      STUDENT.update(this.selectedData);
      // Grid API가 초기화되지 않은 경우 예외 처리
      if (!this.gridApi) {
        console.error('Grid API가 아직 준비되지 않았습니다.');
        alert('Grid API가 아직 준비되지 않았습니다. 다시 시도해 주세요.');
        return;
      }
      // `this.dataSource`에서 수정된 행을 찾고 업데이트
      const index = this.dataSource.findIndex(
        (row) => row.id === this.selectedData.id
      );
      if (index !== -1) {
        // 데이터 수정 (Vue 3에서 반응성을 유지하려면 배열 재할당)
        this.dataSource = [
          ...this.dataSource.slice(0, index),
          { ...this.selectedData },
          ...this.dataSource.slice(index + 1),
        ];

        // 그리드 데이터를 다시 설정
        this.gridApi.setRowData(this.dataSource);

        alert('교육생 정보가 저장되었습니다.');
        console.log('교육생 정보가 저장되었습니다.', this.selectedData);
      } else {
        console.warn('수정하려는 교육생 데이터가 dataSource에 존재하지 않습니다.');
      }
      this.closeEditModal();
    },
    openAddModal() {
      this.selectedData = {
        studentName: '',
        studentPhone: '',
        studentBirth: '',
        studentNumber: '',
        courseTime: 0,
        courseStatus: 'N',
      };
      this.isAdding = true;
      this.showEditModal = true;
    },
    addData() {
      // 저장 로직 구현
      STUDENT.update(this.selectedData)
        .then(() => {
          alert('새로운 교육생이 추가되었습니다.');
          this.getDatas();
        })
        .catch((error) => {
          console.error('새로운 교육생이 추가 실패:', error);
          alert('새로운 교육생이 추가중 문제가 발생했습니다.');
        });
      this.closeEditModal();
    },
    deleteData() {
      if (!this.selectedData.id) {
        alert('삭제할 항목이 선택되지 않았습니다.');
        return;
      }

      // 확인 메시지
      if (!confirm('정말로 이 교육생을 삭제하시겠습니까?')) {
        return;
      }

      // 서버에 삭제 요청 (비동기)
      STUDENT.deleteData(this.selectedData)
        .then(() => {
          alert('교육생 정보가 삭제되었습니다.');
          // 삭제 로직: dataSource에서 해당 항목 제거
          this.dataSource = this.dataSource.filter(
            (teacher) => teacher.id !== this.selectedData.id
          );

          // Grid 업데이트
          if (this.gridApi) {
            this.gridApi.setRowData(this.dataSource);
          }
        })
        .catch((error) => {
          console.error('삭제 실패:', error);
          alert('삭제 중 문제가 발생했습니다.');
        });

      // 모달 닫기
      this.closeEditModal();
    },
  }
}
</script>

<style scoped>
.btn {
  padding: 0.5rem 0.5rem;
  font-size: 1rem;
  line-height: 1;
}

.form-check {
  margin-right: 10px;
  /* 라디오 버튼 간의 간격 */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-dialog {
  max-width: 500px;
  width: 100%;
}

.modal-header {
  display: flex;
  align-items: center;
}

.modal-header .close {
  margin-left: auto;
  /* 닫기 버튼을 오른쪽 끝으로 이동 */
  background: none;
  /* 불필요한 배경 제거 */
  border: none;
  /* 불필요한 테두리 제거 */
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-footer .btn-danger {
  margin-right: auto;
  /* 왼쪽 정렬 */
}

.modal-footer div {
  display: flex;
  gap: 10px;
  /* 저장 및 닫기 버튼 간 간격 */
}
</style>
